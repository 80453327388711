import React from 'react';
import Switch from '../Switch/Switch';
import { Col, Row, Card } from 'react-bootstrap';
import BootstrapInput from '../FormInput/BootstrapInput2';
import { Draggable } from 'react-beautiful-dnd';
import { isNumeric, isString } from '../../helpers/enums';
import { Fragment } from 'react';

/**
 * Component for field on DataEntry create/update form.
 * Props:
 * - onChange: callback called on input change
 * - name: name of parent; parent is dynamic form so inut names must be like parent[0].children
 * - value: object holding all values for DataEntry field (DisplayName, Visible, ReadOnly etc.)
 * - index: unique identifier provided by parent for drag-drop functionality
 */
class Field extends React.Component {

    /**
     * Override default Reacts rules for rerendering of component. Without this whole parent form would be rerendered on every keystroke.
     * This component is rerendered only if sth in value objects will change.
     */
    shouldComponentUpdate = (nextProps) => {
        return (
            this.props.name !== nextProps.name ||
            this.props.value.DisplayName !== nextProps.value.DisplayName ||
            this.props.value.DefaultValue !== nextProps.value.DefaultValue ||
            this.props.value.AssociatedGroups !== nextProps.value.AssociatedGroups ||
            this.props.value.Visible !== nextProps.value.Visible ||
            this.props.value.ReadOnly !== nextProps.value.ReadOnly ||
            this.props.value.Required !== nextProps.value.Required ||
            this.props.value.UseTextarea !== nextProps.value.UseTextarea ||
            this.props.value.UseBtnWidget !== nextProps.value.UseBtnWidget ||

            this.props.value.FormatValidation !== nextProps.value.FormatValidation ||
            this.props.value.FormatValidationErrorMessage !== nextProps.value.FormatValidationErrorMessage ||
            this.props.value.RangeValidationLow !== nextProps.value.RangeValidationLow ||
            this.props.value.RangeValidationHigh !== nextProps.value.RangeValidationHigh ||
            this.props.value.RangeValidationErrorMessage !== nextProps.value.RangeValidationErrorMessage


        );
    }

    render() {
        {/** Whole fieldset is wrapped into Dragable for drag-drop functionality. See react-beautiful-dnd */ }
        const { onChange, name, value } = this.props;
        return (
            <Draggable
                draggableId={this.props.value.EventField.Name}
                index={this.props.index}
                type="FIELDS"
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Card>
                            <Card.Body>
                                <input type="hidden" name={this.props.name + '.EventFieldId'} value={value.EventField.ID} />

                                <Row >
                                    <Col sm={6}>
                                        <Row className="align-items-center">
                                            <Col sm={12}><h4>{this.props.value.EventField.Name}</h4></Col>
                                        </Row>
                                        <Row >
                                            <Col sm={6}><BootstrapInput onChange={onChange} value={value.DisplayName} name={name + '.DisplayName'} label="Display Name" /></Col>
                                            <Col sm={6}><BootstrapInput onChange={onChange} value={value.DefaultValue} name={name + '.DefaultValue'} label="Default Value" /></Col>
                                        </Row>
                                        <Row >
                                            <Col sm={6}><BootstrapInput disableLabel value={value.Visible} name={name + '.Visible'} label="Visible" onChange={onChange} component={Switch} /></Col>
                                            <Col sm={6}><BootstrapInput disableLabel value={value.ReadOnly} name={name + '.ReadOnly'} label="Readonly" onChange={onChange} component={Switch} /></Col>
                                        </Row>
                                        <Row >
                                            <Col sm={6}><BootstrapInput disableLabel value={value.Required} name={name + '.Required'} label="Required" onChange={onChange} component={Switch} /></Col>
                                            {isString(value.EventField.BuiltInType) && value.EventField.ValidationFunctionName !== 'enum'
                                                ? <Col sm={6}><BootstrapInput disableLabel value={value.UseTextarea} name={name + '.UseTextarea'} label="Textarea" onChange={onChange} component={Switch} /></Col>
                                                : null}
                                            {isString(value.EventField.BuiltInType) && value.EventField.ValidationFunctionName === 'enum'
                                                ? <Col sm={6}><BootstrapInput disableLabel value={value.UseBtnWidget} name={name + '.UseBtnWidget'} label="Modern" onChange={onChange} component={Switch} /></Col>
                                                : null}
                                        </Row>
                                    </Col>
                                    <Col sm={6}><BootstrapInput as="textarea" rows="6" onChange={onChange} value={value.AssociatedGroups} name={name + '.AssociatedGroups'} label="Linked Groups & Fields Visibility" /></Col>
                                </Row>

                                {/*format / range validation*/}
                                <Row >
                                    {isString(value.EventField.BuiltInType) && value.EventField.ValidationFunctionName !== 'enum'
                                        ? <Fragment>
                                            <Col sm={6}><BootstrapInput onChange={onChange} value={value.FormatValidation} name={name + '.FormatValidation'} label="Format validation regex" /></Col>
                                            <Col sm={6}><BootstrapInput onChange={onChange} value={value.FormatValidationErrorMessage} name={name + '.FormatValidationErrorMessage'} label="Format validation error message" /></Col>
                                        </Fragment>
                                        : null}
                                    {isNumeric(value.EventField.BuiltInType)
                                        ? <Fragment>
                                            <Col sm={3}><BootstrapInput onChange={onChange} value={value.RangeValidationLow} name={name + '.RangeValidationLow'} label="Range validation low" /></Col>
                                            <Col sm={3}><BootstrapInput onChange={onChange} value={value.RangeValidationHigh} name={name + '.RangeValidationHigh'} label="Range validation high" /></Col>
                                            <Col sm={6}><BootstrapInput onChange={onChange} value={value.RangeValidationErrorMessage} name={name + '.RangeValidationErrorMessage'} label="Range validation error message" /></Col>
                                        </Fragment>
                                        : null}



                                </Row>

                            </Card.Body>
                        </Card>
                    </div>
                )}
            </Draggable>

        );
    }
}

export default Field;